import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { environment } from '@environments/environment';
import { GenericIdDesc} from '@app/_models';
import { map, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {
  constructor(private http: HttpClient) { }

  itemName="departamento";

  getAll(): Observable<GenericIdDesc[]>   {
    return this.http.get<GenericIdDesc[]>(`${environment.apiUrl}/${this.itemName}`);
  }

  getById(id: number): Observable<GenericIdDesc>  {
    if (id === 0) {
      return of(new GenericIdDesc(0,''));
    }
    return this.http.get<GenericIdDesc>(`${environment.apiUrl}/${this.itemName}/${id}`).pipe(
      tap(data => console.log(JSON.stringify(data)))
    );
  }

  insert(obj: GenericIdDesc): Observable<GenericIdDesc>  {
    obj.id=null
    return this.http.post<GenericIdDesc>(`${environment.apiUrl}/${this.itemName}`,obj).pipe(
      tap(data => console.log(`${this.itemName} insert:`,JSON.stringify(data))),
    );
  }

  update(id: number, obj: GenericIdDesc): Observable<GenericIdDesc> {
    return this.http.patch<GenericIdDesc>(`${environment.apiUrl}/${this.itemName}/${id}`,obj)
      .pipe(
        tap(() => console.log(`${this.itemName} update:`,id)),
        // Return the updated item
        map(() => obj)
      );
  }

  deleteProduct(id: number): Observable<{}> {
    return this.http.delete<GenericIdDesc>(`${environment.apiUrl}/${this.itemName}/${id}`)
      .pipe(
        tap(data => console.log(`${this.itemName} delete:`,id))
      );
  }
}
