import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioEditComponent } from './usuario-edit.component';
@Injectable({
  providedIn: 'root'
})
export class UsuarioEditGuard implements CanDeactivate<UsuarioEditComponent> {
  canDeactivate(component: UsuarioEditComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.form.dirty) {
      const itemName = component.form.get('name').value || 'Nuevo Usuario';
      return confirm(`Si abandona esta pagina se perderas los cambios de ${itemName}?`);
    }
    return true;
  }
}
