import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { environment } from '@environments/environment';
import { User} from '@app/_models';
import { map, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll(): Observable<User[]>   {
      return this.http.get<User[]>(`${environment.apiUrl}/usuario`);
    }

    getById(id: number): Observable<User>  {
      if (id === 0) {
        return of(new User(0,'','',null,null,null,null,null));
      }
      return this.http.get<User>(`${environment.apiUrl}/usuario/${id}`).pipe(
        tap(data => console.log(JSON.stringify(data)))
      );
    }

    insert(user: User): Observable<User>  {
      user.id=null
      return this.http.post<User>(`${environment.apiUrl}/usuario`,user).pipe(
        tap(data => console.log('user insert: ' + JSON.stringify(data))),
      );
    }

    update(id: number, user: User): Observable<User> {
      return this.http.patch<User>(`${environment.apiUrl}/usuario/${id}`,user)
        .pipe(
          tap(() => console.log('user update: ' + id)),
          // Return the updated item
          map(() => user)
        );
    }

    deleteProduct(id: number): Observable<{}> {
      return this.http.delete<User>(`${environment.apiUrl}/usuario/${id}`)
        .pipe(
          tap(data => console.log('user delete: ' + id))
        );
    }

    /*
    getAll(): Observable<User[]>   {
      return this.http.get(`${environment.apiUrl}/usuario`).pipe(
        map((data: any[]) => data.map((item: any) => new User(
          item.id,
          item.name,
          item.email,
          item.usuario_nivel_id,
          item.departamento_id,
          "token"
        )))
      );
    }

    getById(id: number): Observable<User>  {
      return this.http.get(`${environment.apiUrl}/usuario/${id}`).pipe(
        map((data: any) => new User(
          data.usuario.id,
          data.usuario.name,
          data.usuario.email,
          data.usuario.usuario_nivel_id,
          data.usuario.departamento_id,
          "token"
        ))
      );
    }*/
}
