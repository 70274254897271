import { Pregunta } from './pregunta';

export class Respuesta {

  constructor(
    public id: number,
    public adc_id: number,
    public pregunta_id: number,
    public rango: string,
    public observacion: string,
    public si_no?: boolean,
    public pregunta?: Pregunta
  ) { }

}
