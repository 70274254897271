import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { environment } from '@environments/environment';
import { map, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Adc } from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class AdcService {
  constructor(private http: HttpClient) { }

  itemName="adc";

  getByTipoFundamento(id: number): Observable<Adc[]>   {
    var obj ={"fundamento_tipo_id": id};
    return this.http.post<Adc[]>(`${environment.apiUrl}/${this.itemName}/tipoFundamento`,obj);
  }

  getById(id: number): Observable<Adc>  {
    if (id === 0) {
      return of(new Adc(0,null,null,null,'',null,null,'',null,null,'','',''));
    }
    return this.http.get<Adc>(`${environment.apiUrl}/${this.itemName}/${id}`).pipe(
      tap(data => console.log(JSON.stringify(data)))
    );
  }

  insert(obj: Adc): Observable<Adc>  {
    obj.id=null
    return this.http.post<Adc>(`${environment.apiUrl}/${this.itemName}`,obj).pipe(
      tap(data => console.log(`${this.itemName} insert:`,JSON.stringify(data))),
    );
  }

  update(id: number, obj: Adc): Observable<Adc> {
    return this.http.patch<Adc>(`${environment.apiUrl}/${this.itemName}/${id}`,obj)
      .pipe(
        tap(() => console.log(`${this.itemName} update:`,id)),
        // Return the updated item
        map(() => obj)
      );
  }

  deleteProduct(id: number): Observable<{}> {
    return this.http.delete<Adc>(`${environment.apiUrl}/${this.itemName}/${id}`)
      .pipe(
        tap(data => console.log(`${this.itemName} delete:`,id))
      );
  }
}
