import { Orden } from './orden';
import { Respuesta } from './respuesta';


export class Adc {

  constructor(
    public id: number,
    public orden_id: number,
    public fechaInicio: Date,
    public fechaFin: Date,
    public diasAbierto: string,
    public horarioInicio: string,
    public horarioFin: string,
    public turnos: string,
    public noEmpleados: number,
    public noEmpleadosProduccion: number,
    public autorizacionAviso: string,
    public descripcionEstablecimiento: string,
    public giro: string,
    public actualizacionActa?: Date,
    public actualizacionDictamen?: Date,
    public actualizacionCitatorio?: Date,
    public citatorio?: boolean,
    public observacionCitatorio?: string,
    public respuestas?: Respuesta[],
    //public orden?: Orden,
  ) { }

}
