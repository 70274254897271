import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { User, GenericIdDesc } from '@app/_models';
import { UserService, UsuarioNivelService, DepartamentoService } from '@app/_services';

import { NumberValidators } from '../shared/number.validator';
import { GenericValidator } from '../shared/generic-validator';

@Component({templateUrl: './usuario-edit.component.html'})

export class UsuarioEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  pageTitle = 'Item Edit';
  errorMessage: string;
  form: FormGroup;

  id;
  usuario: User;
  usuario_nivel: GenericIdDesc[] = [];
  departamentos: GenericIdDesc[] = [];
  private sub: Subscription;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };

  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private usuarioNivelService: UsuarioNivelService,
              private departamentoService: DepartamentoService) {

    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      name: {
        required: 'Nombre es un campo obligatorio.',
        minlength: 'Nombre debe ser al menos de 3 caracteres.',
        maxlength: 'Nombre no puede exceder 50 caracteres.'
      },
      email: {
        email: 'Formato invalido.',
        required: 'Email es un campo obligatorio.'
      },
      password: {
        required: 'Contraseña es un campo obligatorio.',
        minlength: 'Contraseña debe ser al menos de 6 caracteres.',
      },
      usuario_nivel_id: {
        required: 'Nivel es un campo obligatorio.',
      },
      departamento_id: {
        required: 'GenericIdDesc es un campo obligatorio.',
      }
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.usuarioNivelService.getAll().subscribe(results => {
      this.usuario_nivel = results;
    });

    this.departamentoService.getAll().subscribe(results => {
      this.departamentos = results;
    });

    this.form = this.fb.group({
      name: ['', [Validators.required,
                         Validators.minLength(3),
                         Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,
                        Validators.minLength(6)]],
      usuario_nivel_id: [null, Validators.required],
      departamento_id: [null, Validators.required]
    });

    // Read the product Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.id = +params.get('id');
        this.getItem(this.id);
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    // This is required because the valueChanges does not provide notification on blur
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    // so we only need to subscribe once.
    merge(this.form.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.form);
    });
  }

  getItem(id: number): void {
    this.userService.getById(id)
      .subscribe({
        next: (user: User) => this.displayItem(user),
        error: err => this.router.navigate(['/usuarios'])
      });
  }

  displayItem(user: User): void {
    if (this.form) {
      this.form.reset();
    }
    this.usuario = user;

    if (this.usuario.id === 0) {
      this.pageTitle = 'Add Item';
    } else {
      this.pageTitle = `Edit Item: ${this.usuario.name}`;
      //si es edicion no pide cambio de password
      const passwordControl = this.form.get('password');
      passwordControl.clearValidators();
      passwordControl.updateValueAndValidity();
    }

    // Update the data on the form
    this.form.patchValue({
      name: this.usuario.name,
      email: this.usuario.email,
      usuario_nivel_id: this.usuario.usuario_nivel_id,
      departamento_id: this.usuario.departamento_id,
    });
  }

  delete(): void {
    if (this.usuario.id === 0) {
      // Don't delete, it was never saved.
      this.onSaveComplete();
    } else {
      if (confirm(`Desea borrar el usuario: ${this.usuario.name}?`)) {
        this.userService.deleteProduct(this.usuario.id)
          .subscribe({
            next: () => this.onSaveComplete(),
            error: err => this.errorMessage = err
          });
      }
    }
  }

  save(): void {
    if (this.form.valid) {
      if (this.form.dirty) {
        const p = { ...this.usuario, ...this.form.value };
        if (p.id === 0) {
          this.userService.insert(p)
            .subscribe({
              next: () => this.onSaveComplete(),
              error: err => this.errorMessage = err
            });
        } else {
          delete p.id;
          delete p.password;
          delete p.usuario_nivel;
          delete p.departamento;
          this.userService.update(this.id,p)
            .subscribe({
              next: () => this.onSaveComplete(),
              error: err => this.errorMessage = err
            });
        }
      } else {
        this.onSaveComplete();
      }
    } else {
      this.errorMessage = 'Corrija los errores de validación.';
    }
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.form.reset();
    this.router.navigate(['/usuarios']);
  }
}
