import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { environment } from '@environments/environment';
import { map, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Orden } from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class OrdenService {
  constructor(private http: HttpClient) { }

  itemName="orden";

  getActas(): Observable<Orden[]>   {
    return this.http.post<Orden[]>(`${environment.apiUrl}/${this.itemName}/acta`,null);
  }

  getDictamenes(): Observable<Orden[]>   {
    return this.http.post<Orden[]>(`${environment.apiUrl}/${this.itemName}/dictamen`,null);
  }

  getCitatorios(): Observable<Orden[]>   {
    return this.http.post<Orden[]>(`${environment.apiUrl}/${this.itemName}/citatorio`,null);
  }
}
