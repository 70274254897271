import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Pregunta } from '@app/_models';
import { PreguntaService } from '@app/_services';

import { GenericValidator } from '../shared/generic-validator';

@Component({templateUrl: './pregunta-edit.component.html'})

export class PreguntaEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  pageTitle = 'Item Edit';
  errorMessage: string;
  form: FormGroup;

  pregunta: Pregunta;
  private sub: Subscription;

  fundamentoTipoid;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private preguntaService: PreguntaService) {

    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      pregunta: {
        required: 'Nombre es un campo obligatorio.'
      },
      anomalia: {
        required: 'Anomalia es un campo obligatorio.'
      },
      fundamento: {
        required: 'Fundamento es un campo obligatorio.'
      },
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      pregunta: ['', Validators.required],
      anomalia: ['', Validators.required],
      fundamento: ['', Validators.required],
    });

    // Read the product Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        const id = +params.get('id');
        this.fundamentoTipoid = params.get('fundamentoTipoId');
        this.getItem(id);
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    // This is required because the valueChanges does not provide notification on blur
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    // so we only need to subscribe once.
    merge(this.form.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.form);
    });
  }

  getItem(id: number): void {
    this.preguntaService.getById(id)
      .subscribe({
        next: (pregunta: Pregunta) => this.displayItem(pregunta),
        error: err => this.router.navigate(['/preguntas'])
      });
  }

  displayItem(pregunta: Pregunta): void {
    if (this.form) {
      this.form.reset();
    }
    this.pregunta = pregunta;

    if (this.pregunta.id === 0) {
      this.pageTitle = 'Add Item';
      this.pregunta.fundamento_tipo_id=this.fundamentoTipoid;
    } else {
      this.pageTitle = `Edit Item: ${this.pregunta.numero}`;
    }

    // Update the data on the form
    this.form.patchValue({
      pregunta: this.pregunta.pregunta,
      anomalia: this.pregunta.anomalia,
      fundamento: this.pregunta.fundamento
    });
  }

  delete(): void {
    if (this.pregunta.id === 0) {
      // Don't delete, it was never saved.
      this.onSaveComplete();
    } else {
      if (confirm(`Desea borrar la pregunta numero: ${this.pregunta.numero}?`)) {
        this.preguntaService.deleteProduct(this.pregunta.id)
          .subscribe({
            next: () => this.onSaveComplete(),
            error: err => this.errorMessage = err
          });
      }
    }
  }

  save(): void {
    if (this.form.valid) {
      if (this.form.dirty) {
        const p = { ...this.pregunta, ...this.form.value };
        if (p.id === 0) {
          this.preguntaService.insert(p)
            .subscribe({
              next: () => this.onSaveComplete(),
              error: err => this.errorMessage = err
            });
        } else {
          delete p.id;
          delete p.numero;
          delete p.fundamento_tipo;
          this.preguntaService.update(this.pregunta.id,p)
            .subscribe({
              next: () => this.onSaveComplete(),
              error: err => this.errorMessage = err
            });
        }
      } else {
        this.onSaveComplete();
      }
    } else {
      this.errorMessage = 'Corrija los errores de validación.';
    }
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.form.reset();
    this.router.navigate(['/preguntas/fundamentoTipo/',this.pregunta.fundamento_tipo_id]);
  }
}
