import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { PreguntaEditComponent } from './pregunta-edit.component';
@Injectable({
  providedIn: 'root'
})
export class PreguntaEditGuard implements CanDeactivate<PreguntaEditComponent> {
  canDeactivate(component: PreguntaEditComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.form.dirty) {
      const itemName = component.form.get('pregunta').value || 'Nueva Pregunta';
      return confirm(`Si abandona esta pagina se perderas los cambios de ${itemName}?`);
    }
    return true;
  }
}
