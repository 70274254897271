import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role

            /*console.log("AuthGuard cUser.role", currentUser.role);

            var x =JSON.parse(JSON.stringify(route.data));;
            console.log("AuthGuard roles", route.data.roles);

            if (route.data.roles)
            console.log("AuthGuard roles indexOf", route.data.roles.includes(currentUser.role));*/

            if (route.data.roles && route.data.roles.indexOf(currentUser.usuario_nivel_id) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            console.log("AuthGuard passed");
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
