import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { UsuarioListComponent, UsuarioEditComponent } from './usuarios';
import { PreguntaListComponent, PreguntaEditComponent } from './preguntas';
import { ActaListComponent, ActaEditComponent } from './actas';
import { DictamenListComponent, DictamenEditComponent } from './dictamenes';
import { CitatorioListComponent, CitatorioEditComponent } from './citatorios';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        DataTablesModule,
        NgMultiSelectDropDownModule.forRoot(),
        FormsModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        AdminComponent,
        LoginComponent,
        UsuarioListComponent,
        UsuarioEditComponent,
        PreguntaListComponent,
        PreguntaEditComponent,
        ActaListComponent,
        ActaEditComponent,
        DictamenListComponent,
        DictamenEditComponent,
        CitatorioListComponent,
        CitatorioEditComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
