import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { UsuarioListComponent, UsuarioEditComponent, UsuarioEditGuard } from './usuarios';
import { PreguntaListComponent, PreguntaEditComponent, PreguntaEditGuard } from './preguntas';
import { ActaListComponent, ActaEditComponent, ActaEditGuard } from './actas';
import { DictamenListComponent, DictamenEditComponent, DictamenEditGuard } from './dictamenes';
import { CitatorioListComponent, CitatorioEditComponent, CitatorioEditGuard } from './citatorios';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: { roles: [1] }
    },
    {
        path: 'usuarios',
        component: UsuarioListComponent,
        canActivate: [AuthGuard],
        data: { roles: [1] }
    },
    {
        path: 'usuarios/:id/edit',
        component: UsuarioEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UsuarioEditGuard],
        data: { roles: [1] }
    },
    {
      path: 'preguntas',
      component: PreguntaListComponent,
      canActivate: [AuthGuard],
      data: { roles: [1] }
    },
    {
      path: 'preguntas/fundamentoTipo/:fundamentoTipoId',
      component: PreguntaListComponent,
      canActivate: [AuthGuard],
      data: { roles: [1] }
    },
    {
        path: 'preguntas/:id/edit',
        component: PreguntaEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PreguntaEditGuard],
        data: { roles: [1] }
    },
    {
      path: 'preguntas/:id/:fundamentoTipoId/edit',
      component: PreguntaEditComponent,
      canActivate: [AuthGuard],
      canDeactivate: [PreguntaEditGuard],
      data: { roles: [1] }
    },
    {
        path: 'actas',
        component: ActaListComponent,
        canActivate: [AuthGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'actas/:id/:ordenId/:fundamentoTipoId/edit',
        component: ActaEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ActaEditGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'dictamenes',
        component: DictamenListComponent,
        canActivate: [AuthGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'dictamenes/:id/edit',
        component: DictamenEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [DictamenEditGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'citatorios',
        component: CitatorioListComponent,
        canActivate: [AuthGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'citatorios/:id/edit',
        component: CitatorioEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CitatorioEditGuard],
        data: { roles: [1,2] }
    },
    {
        path: 'login',
        component: LoginComponent
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
