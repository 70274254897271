import { GenericIdDesc } from './generic-id-desc';

export class Establecimiento {

  constructor(
    public id: number,
    public fechaProgramacion: Date,
    public expediente: string,
    public rfc: string,
    public razonSocial: string,
    public denominacion: string,
    public scian_id: number,
    public cp: number,
    public direccion: string,
    public colonia: string,
    public entreCalles: string,
    public departamento_id: number,
    public departamento?: GenericIdDesc
  ) { }

}
