import { GenericIdDesc } from './generic-id-desc';


export class User {

  constructor(
    public id: number,
    public name: string,
    public email: string,
    public usuario_nivel_id: number,
    public departamento_id: number,
    public token?: string,
    public usuario_nivel?: GenericIdDesc,
    public departamento?: GenericIdDesc
  ) { }

}
