import { GenericIdDesc } from './generic-id-desc';

export class Pregunta {

  constructor(
    public id: number,
    public numero: number,
    public fundamento_tipo_id: number,
    public pregunta: string,
    public anomalia: string,
    public fundamento: string,
    public fundamento_tipo?: GenericIdDesc
  ) { }

}
