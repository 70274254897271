import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { environment } from '@environments/environment';
import { Pregunta} from '@app/_models';
import { map, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreguntaService {
  constructor(private http: HttpClient) { }

  itemName="pregunta";

  getByTipoFundamento(id: number): Observable<Pregunta[]>   {
    var obj ={"fundamento_tipo_id": id};
    return this.http.post<Pregunta[]>(`${environment.apiUrl}/${this.itemName}/tipoFundamento`,obj);
  }

  getById(id: number): Observable<Pregunta>  {
    if (id === 0) {
      return of(new Pregunta(0,null,null,'','',''));
    }
    return this.http.get<Pregunta>(`${environment.apiUrl}/${this.itemName}/${id}`).pipe(
      tap(data => console.log(JSON.stringify(data)))
    );
  }

  insert(obj: Pregunta): Observable<Pregunta>  {
    obj.id=null
    return this.http.post<Pregunta>(`${environment.apiUrl}/${this.itemName}`,obj).pipe(
      tap(data => console.log(`${this.itemName} insert:`,JSON.stringify(data))),
    );
  }

  update(id: number, obj: Pregunta): Observable<Pregunta> {
    return this.http.patch<Pregunta>(`${environment.apiUrl}/${this.itemName}/${id}`,obj)
      .pipe(
        tap(() => console.log(`${this.itemName} update:`,id)),
        // Return the updated item
        map(() => obj)
      );
  }

  deleteProduct(id: number): Observable<{}> {
    return this.http.delete<Pregunta>(`${environment.apiUrl}/${this.itemName}/${id}`)
      .pipe(
        tap(data => console.log(`${this.itemName} delete:`,id))
      );
  }
}
