import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { Pregunta, Adc, Respuesta } from '@app/_models';
import { AdcService, PreguntaService } from '@app/_services';

@Component({templateUrl: './dictamen-edit.component.html'})

export class DictamenEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  pageTitle = 'Dictamen';
  errorMessage: string;
  form: FormGroup;

  id;
  ordenId;
  adc: Adc;
  preguntas: Pregunta[] = [];
  private sub: Subscription;

  get respuestas(): FormArray {
    return this.form.get('respuestas') as FormArray;
  }

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private adcService: AdcService) {
  }

  ngOnInit(): void {
      this.form = this.fb.group({
      respuestas: this.fb.array([])
    });

    // Read the product Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.id = +params.get('id');
        console.log("params: ",params);
        this.getItem(this.id);
      }
    );
  }

  getItem(id: number): void {
    this.adcService.getById(id)
      .subscribe({
        next: (adc: Adc) => this.displayItem(adc),
        error: err => this.router.navigate(['/dictamenes'])
      });
  }

  displayItem(adc: Adc): void {
    this.adc = adc;
    this.buildRespuestas(this.adc.respuestas);
  }

  buildRespuestas(respuestas: Respuesta[]): void {
    respuestas.forEach(respuesta => {
      this.respuestas.push(this.fb.group({
        id: respuesta.id,
        observacion: [respuesta.observacion, Validators.required],
        si_no: ['', Validators.required]
      }));
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  save(): void {
    if (this.form.valid) {
      if (this.form.dirty)
      {
        const obj = this.form.value;
        console.log("trying to save:",obj);

        this.adcService.update(this.id,obj)
        .subscribe({
          next: () => this.onSaveComplete(),
          error: err => this.errorMessage = err
        });
      }
      else
      {
        this.onSaveComplete();
      }
    } else {
      this.errorMessage = 'Corrija los errores de validación.';
    }
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.form.reset();
    this.form.markAsPristine();
    this.router.navigate(['/dictamenes']);
  }
}
