import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { Pregunta } from '@app/_models';
import { AdcService, PreguntaService } from '@app/_services';

import { NumberValidators } from '../shared/number.validator';
import { GenericValidator } from '../shared/generic-validator';

import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({templateUrl: './acta-edit.component.html'})

export class ActaEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  pageTitle = 'Acta';
  errorMessage: string;
  form: FormGroup;

  id;
  ordenId;
  fundamentoTipoId;

  preguntas: Pregunta[] = [];
  private sub: Subscription;

  get respuestas(): FormArray {
    return this.form.get('respuestas') as FormArray;
  }

  listDias = [];
  listTurnos = [];
  dropdownSettings:IDropdownSettings = {};

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };

  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private adcService: AdcService,
              private preguntaService: PreguntaService) {

    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      fechaInicio: {
        required: 'Fecha Inicio es un campo obligatorio.'
      },
      fechaFin: {
        required: 'Fecha Fin es un campo obligatorio.'
      },
      diasAbierto: {
        required: 'Dias Abierto es un campo obligatorio.'
      },
      horarioInicio: {
        required: 'Horario Inicio es un campo obligatorio.'
      },
      horarioFin: {
        required: 'Horario Fin es un campo obligatorio.'
      },
      turnos: {
        required: 'Turnos es un campo obligatorio.'
      },
      noEmpleados: {
        required: 'N° de Empleados es un campo obligatorio.'
      },
      noEmpleadosProduccion: {
        required: 'N° de Empleados Produccion es un campo obligatorio.'
      },
      autorizacionAviso: {
        required: 'Autorizacion Aviso es un campo obligatorio.'
      },
      descripcionEstablecimiento: {
        required: 'Descripcion Establecimiento es un campo obligatorio.'
      },
      giro: {
        required: 'Giro es un campo obligatorio.'
      },
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {

    this.listDias = [
      { id: 1, text: 'Lunes' },
      { id: 2, text: 'Martes' },
      { id: 3, text: 'Miercoles' },
      { id: 4, text: 'Jueves' },
      { id: 5, text: 'Viernes' },
      { id: 6, text: 'Sabado' },
      { id: 7, text: 'Domingo' }
    ];

    this.listTurnos = [
      { id: 1, text: 'Matutino' },
      { id: 2, text: 'Vespertino' },
      { id: 3, text: 'Diurno' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Seleccionar Todos'
    };

    this.form = this.fb.group({
      fechaInicio: ['', Validators.required],
      fechaFin: ['', Validators.required],
      diasAbierto: ['', Validators.required],
      horarioInicio: ['', Validators.required],
      horarioFin: ['', Validators.required],
      turnos: ['', Validators.required],
      noEmpleados: ['', Validators.required],
      noEmpleadosProduccion: ['', Validators.required],
      autorizacionAviso: ['', Validators.required],
      descripcionEstablecimiento: ['', Validators.required],
      giro: ['', Validators.required],
      respuestas: this.fb.array([])
    });

    // Read the product Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.id = +params.get('id');
        this.ordenId = +params.get('ordenId');
        this.fundamentoTipoId = +params.get('fundamentoTipoId');
        console.log("params: ",params);

        this.preguntaService.getByTipoFundamento(this.fundamentoTipoId).pipe(first()).subscribe(preguntas => {
          this.preguntas = preguntas;
          this.buildRespuestas(this.preguntas);
        });
      }
    );
  }

  buildRespuestas(preguntas: Pregunta[]): void {
    preguntas.forEach(pregunta => {
      this.respuestas.push(this.fb.group({
        rango: [1, Validators.required],
        observacion: ['', Validators.required]
      }));
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    // This is required because the valueChanges does not provide notification on blur
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    // so we only need to subscribe once.
    merge(this.form.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.form);
    });
  }

  save(): void {
    if (this.form.valid) {
      if (this.form.dirty)
      {
        const obj = this.form.value;
        obj.orden_id=this.ordenId;
        obj.diasAbierto=Array.prototype.map.call(obj.diasAbierto, s => s.text).toString();
        obj.turnos=Array.prototype.map.call(obj.turnos, s => s.text).toString();

        for (var i = 0; i < obj.respuestas.length; i++)
        {
          obj.respuestas[i].pregunta_id = this.preguntas[i].id;
        }

        console.log("trying to save:",obj);

        this.adcService.insert(obj)
        .subscribe({
          next: () => this.onSaveComplete(),
          error: err => this.errorMessage = err
        });
      }
      else
      {
        this.onSaveComplete();
      }
    } else {
      this.errorMessage = 'Corrija los errores de validación.';
    }
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.form.reset();
    this.form.markAsPristine();
    this.router.navigate(['/actas']);
  }
}
