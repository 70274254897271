import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { DictamenEditComponent } from './dictamen-edit.component';
@Injectable({
  providedIn: 'root'
})
export class DictamenEditGuard implements CanDeactivate<DictamenEditComponent> {
  canDeactivate(component: DictamenEditComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.form.dirty) {
      return confirm(`Desea abandonar esta pagina?. Si lo hace se perderan los cambios.`);
    }
    return true;
  }
}
