import { GenericIdDesc } from './generic-id-desc';
import { Establecimiento } from './establecimiento';
import { Firmante } from './firmante';
import { Adc } from './adc';

export class Orden {

  constructor(
    public id: number,
    public fechaProgramacion: Date,
    public establecimiento_id: number,
    public firmante_id: number,
    public asunto_id: number,
    public objeto_id: number,
    public fundamento_tipo_id: number,
    public acta_usuario_id?: number,
    public dictamen_usuario_id?: number,
    public citatorio_usuario_id?: number,
    //public verificadores?: Verificador[],
    public establecimiento?: Establecimiento,
    public firmante?: Firmante,
    public asunto?: GenericIdDesc,
    public objeto?: GenericIdDesc,
    public fundamento_tipo?: GenericIdDesc,
    public adc?: Adc
  ) { }

}
