import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ActaEditComponent } from './acta-edit.component';
@Injectable({
  providedIn: 'root'
})
export class ActaEditGuard implements CanDeactivate<ActaEditComponent> {
  canDeactivate(component: ActaEditComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.form.dirty) {
      return confirm(`Desea abandonar esta pagina?. Si lo hace se perderan los cambios.`);
    }
    return true;
  }
}
