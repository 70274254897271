import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';

import { Pregunta, GenericIdDesc } from '@app/_models';
import { PreguntaService, FundamentoTipoService } from '@app/_services';
import { Subject, Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute } from '@angular/router';

@Component({ templateUrl: 'pregunta-list.component.html' })
export class PreguntaListComponent implements  OnDestroy, OnInit {
    loading = false;
    preguntas: Pregunta[] = [];
    fundamentoTipos: GenericIdDesc[] = [];
    fundamentoTipoId;
    fundamentoTipoIdSelected=0;
    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<any> = new Subject();
    dtOptions: any = {};

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    private     _count       : number;
    @Input() get count()     : number  { return this._count; }
            set count(value : number) { this.setCount(value) }
    @Output()    countChange = new EventEmitter<number>();

    private sub: Subscription;

    constructor(private route: ActivatedRoute,
                private preguntaService: PreguntaService,
                private fundamentoTipoService: FundamentoTipoService) { }

    ngOnInit() {

        this.dtOptions = {
          paging:   true,
          pagingType: 'full_numbers',
          ordering: true,
          info:     false,
          responsive: true,
          columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
          ],
          language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
		      },
          order: []
        };

        // Read the product Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
          params => {
            this.fundamentoTipoId = params.get('fundamentoTipoId');
            console.log("params: ",params);
          }
        );

        this.loading = true;
        this.fundamentoTipoService.getAll().subscribe(results => {
          this.fundamentoTipos = results;
          this.fundamentoTipoIdSelected = (this.fundamentoTipoId) ? this.fundamentoTipoId : this.fundamentoTipos[0].id;
          this.setCount(this.fundamentoTipoIdSelected);
          this.preguntaService.getByTipoFundamento(this.fundamentoTipoIdSelected).pipe(first()).subscribe(preguntas => {
              this.loading = false;
              this.preguntas = preguntas;
              this.dtTrigger.next();
          });
        });


    }

    onSelectChange(value: number){
      this.preguntaService.getByTipoFundamento(value).pipe(first()).subscribe(preguntas => {
        this.preguntas = preguntas;
        this.setCount(value);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      });
    }

    isThisSelected(id:number): boolean{
      return this.fundamentoTipoId==id;
    }

    setCount(value) {
      this._count = value;
      this.countChange.emit(value);
    }

    delete(pregunta: Pregunta): void {
      if (confirm(`Desea borrar la pregunta numero: ${pregunta.numero}?`)) {
        this.preguntaService.deleteProduct(pregunta.id).subscribe({
          next: () => this.onSelectChange(pregunta.fundamento_tipo_id)
        });
      }
    }

    ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTrigger.unsubscribe();
      this.sub.unsubscribe();
    }
}
