import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { Orden } from '@app/_models';
import { OrdenService } from '@app/_services';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({ templateUrl: 'dictamen-list.component.html' })
export class DictamenListComponent implements  OnDestroy, OnInit {
    loading = false;
    ordenes: Orden[] = [];
    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<any> = new Subject();
    dtOptions: any = {};

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    constructor(private ordenService: OrdenService) { }

    ngOnInit() {

        this.dtOptions = {
          paging:   true,
          pagingType: 'full_numbers',
          ordering: true,
          info:     false,
          responsive: true,
          columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
          ],
          language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
		      },
          order: []
        };

        this.loading = true;
        this.ordenService.getDictamenes().pipe(first()).subscribe(ordenes => {
            this.loading = false;
            this.ordenes = ordenes;
            console.log(ordenes);
            this.dtTrigger.next();
        });
    }

    ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTrigger.unsubscribe();
    }

}
