import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({ templateUrl: 'usuario-list.component.html' })
export class UsuarioListComponent implements  OnDestroy, OnInit {
    loading = false;
    users: User[] = [];
    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<any> = new Subject();
    dtOptions: any = {};

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    constructor(private userService: UserService) { }

    ngOnInit() {

        this.dtOptions = {
          paging:   true,
          pagingType: 'full_numbers',
          ordering: true,
          info:     false,
          responsive: true,
          columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
          ],
          language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
		      },
          order: []
        };

        this.loading = true;
        this.userService.getAll().pipe(first()).subscribe(users => {
            this.loading = false;
            this.users = users;
            console.log(users);
            this.dtTrigger.next();
        });
    }

    ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTrigger.unsubscribe();
    }

    delete(user: User): void {
      if (confirm(`Desea borrar el usuario: ${user.name}?`)) {
        this.userService.deleteProduct(user.id).subscribe({
          next: () => this.rerender(user)
        });
      }
    }

    rerender(user: User): void {
      this.users = this.users.filter(item => item !== user);

      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }

}
